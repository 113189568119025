/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { getTranslatedHeadline } from "../../../helpers/propertyHelper";
import { getTranslatedValue, translateStatic } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";
import bed from "../images/bed.svg";
import bathtub from "../images/bathtub.svg";
import totalArea from "../images/total-area.svg";
import builtArea from "../images/built-area.svg";
import buildingtag from "../images/building.png";

import "../../theme5/scss/home-carousel.scss";
import * as theme5Styles from "../scss/theme5.module.scss";

const BuildingList = ({ buildings, locale, defaultLocale, is_carousel=false }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          setting: {
            display_watermark
          }
        }
      }
    }
  } = siteData;

  const renderBuildings = () => {
    return buildings.map((building) => {
      const { 
        pictures, 
        property_type, 
        measurement_unit
      } = building;

      const picture =
        pictures.length > 0
          ? prepareImageURL(pictures[0], website_id, 480, 320, display_watermark == 2)
          : property1;

      return (
        <div className={theme5Styles.colm} key={building.uuid}>
          <div className={`${theme5Styles.productBox}`}>
            <Link
              to={`/${locale}/building/${building.uuid}`}
              className={theme5Styles.image}
            >
              <img alt="image" src={picture} />

              {(() => {
                if (property_type && property_type.translations.length > 0) {
                  const propertyType = getTranslatedValue(
                    property_type.translations,
                    "name",
                    locale,
                    defaultLocale
                  );
                  if (propertyType) {
                    return (
                      <span className={`${theme5Styles.buCatg}`}>
                        <span className={`${theme5Styles.tag}`}>
                          <img src={buildingtag} alt="" />
                        </span>
                        {propertyType}
                      </span>
                    );
                  }
                }
              })()}
            </Link>
            <div className={theme5Styles.details}>
              <h4>{building.name}</h4>
              <div className={`${theme5Styles.category}`}>
                <span
                  className={`${theme5Styles.catg}`}
                  dangerouslySetInnerHTML={{
                    __html:
                      getTranslatedHeadline(
                        building,
                        locale,
                        defaultLocale
                      ) || "NA",
                  }}
                ></span>
              </div>
              <p>{building.location}</p>
              <div className={theme5Styles.features}>
                {building.bedrooms != 0 && <div className={theme5Styles.li}>
                  <img alt="image" src={bed} />
                  <span>{building.bedrooms} {translateStatic("bedrooms", locale, defaultLocale)}</span>
                </div>}
                {building.bathrooms != 0 && <div className={theme5Styles.li}>
                  <img alt="image" src={bathtub} />
                  <span>{building.bathrooms} {translateStatic("bathrooms", locale, defaultLocale)}</span>
                </div>}
                {building.land_area_value && measurement_unit && <div className={`${theme5Styles.li} ${theme5Styles.sm}`}>
                  <img alt="image" src={totalArea} />
                  <span>
                    {building.land_area_value + ' ' + (measurement_unit.name || "")}&nbsp;
                    {translateStatic("total", locale, defaultLocale)}
                  </span>
                </div>}
                {building.built_in_area_value && measurement_unit && <div className={`${theme5Styles.li} ${theme5Styles.sm}`}>
                  <img alt="image" src={builtArea} />
                  <span>
                    {building.built_in_area_value + ' ' + (measurement_unit.name || "")}&nbsp;
                    {translateStatic("built", locale, defaultLocale)}
                  </span>
                </div>}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={`${theme5Styles.productRowContainer}`}>
      {(() => {
        if (is_carousel && buildings.length > 3) {
          let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'theme5-product-slider',

            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 639,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              },
            ]
          };

          return (
            <div className={`${theme5Styles.productSlideContainer}`}>
              <Slider {...settings}>{renderBuildings()}</Slider>
            </div>
          );
        }

        return (
          <div className={`${theme5Styles.productRow}`}>{renderBuildings()}</div>
        );
      })()}
    </div>
  );
};

export default BuildingList;
