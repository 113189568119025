/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { getTranslatedHeadline } from "../../../helpers/propertyHelper";
import { getTranslatedValue, translateStatic } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";

import "../scss/home-carousel.scss";
import * as theme3Styles from "../scss/theme3.module.scss";

const BuildingList = ({ buildings, locale, defaultLocale, is_carousel=false }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          setting: {
            display_watermark
          }
        }
      }
    }
  } = siteData;

  const renderBuildings = () => {
    return buildings.map((building) => {
      const {
        pictures,
        property_type,
        measurement_unit,
      } = building;

      const picture =
        pictures.length > 0
          ? prepareImageURL(pictures[0], website_id, 480, 320, display_watermark == 2)
          : property1;

      return (
        <div className={theme3Styles.colm} key={building.uuid}>
          <div className={`${theme3Styles.productBox}`}>
            <Link
              to={`/${locale}/building/${building.uuid}`}
              className={theme3Styles.image}
            >
              <img alt="image" src={picture} />
              {(() => {
                if (property_type && property_type.translations.length > 0) {
                  return (
                    <span className={theme3Styles.type}>
                      {getTranslatedValue(
                        property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                      )}
                    </span>
                  );
                }
              })()}
              <span className={theme3Styles.uuid}>{building.name}</span>
            </Link>
            <div className={theme3Styles.details}>
              <div className={`${theme3Styles.priceC}`}>
                <ul className={theme3Styles.features}>
                  {building.bedrooms != 0 && <li>
                    {building.bedrooms}
                    <abbr>bds</abbr>
                  </li>}
                  {building.bathrooms != 0 && <li>
                    {building.bathrooms}
                    <abbr>ba</abbr>
                  </li>}
                  {building.land_area_value && measurement_unit && <li>
                    {building.land_area_value}
                    <abbr>
                      {" " + measurement_unit ? measurement_unit.name : ""}
                    </abbr>
                    <abbr>{translateStatic("total", locale, defaultLocale)}</abbr>
                  </li>}
                  {building.built_in_area_value && measurement_unit && <li>
                    {building.built_in_area_value}
                    <abbr>
                      {" " + measurement_unit ? measurement_unit.name : ""}
                    </abbr>
                    <abbr>{translateStatic("built", locale, defaultLocale)}</abbr>
                  </li>}
                </ul>
              </div>
              <h4
                dangerouslySetInnerHTML={{
                  __html:
                    getTranslatedHeadline(
                      building,
                      locale,
                      defaultLocale
                    ) || "",
                }}
              ></h4>
              <h5>{building.location}</h5>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`${theme3Styles.productRowContainer}`}>
      {(() => {
        if (is_carousel && buildings.length > 3) {
          let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'theme3-product-slider',

            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 599,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              },
            ]
          };

          return (
            <div className={`${theme3Styles.productSlideContainer}`}>
              <Slider {...settings}>{renderBuildings()}</Slider>
            </div>
          );
        }

        return (
          <div className={`${theme3Styles.productRow}`}>{renderBuildings()}</div>
        );
      })()}
    </div>
  );
};

export default BuildingList;
