/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { getTranslatedHeadline } from "../../../helpers/propertyHelper";
import { getTranslatedValue } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";
import bedIcon from "../../../images/bed.svg";
import bathIcon from "../../../images/bathtub.svg";
import builtAreaIcon from "../../../images/built-area.svg";
import landAreaIcon from "../../../images/plans.png";

import "../scss/home-carousel.scss";
import * as theme2Styles from "../scss/theme2.module.scss";

const BuildingList = ({ buildings, locale, defaultLocale, is_carousel=false }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          setting: {
            display_watermark
          }
        }
      }
    }
  } = siteData;

  const renderBuildings = () => {
    return buildings.map((building) => {
      const { 
        pictures, 
        property_type, 
        measurement_unit
      } = building;

      const picture =
        pictures.length > 0
          ? prepareImageURL(pictures[0], website_id, 480, 320, display_watermark == 2)
          : property1;

      return (
        <div className={theme2Styles.colm} key={building.uuid}>
          <div className={`${theme2Styles.productBox}`}>
            <Link
              to={`/${locale}/building/${building.uuid}`}
              className={theme2Styles.image}
            >
              <img alt="image" src={picture} />
              {(() => {
                if (property_type && property_type.translations.length > 0) {
                  const propertyType = getTranslatedValue(
                    property_type.translations,
                    "name",
                    locale,
                    defaultLocale
                  );
                  if (propertyType) {
                    return (
                      <span className={theme2Styles.type}>
                        {propertyType}
                      </span>
                    );
                  }
                }
              })()}
            </Link>
            <div className={theme2Styles.details}>
              <div className={theme2Styles.top}>
                <h3>
                  <Link to={`/${locale}/building/${building.uuid}`}>
                    {building.name}
                  </Link>
                </h3>
                <h4
                  dangerouslySetInnerHTML={{
                    __html:
                      getTranslatedHeadline(
                        building,
                        locale,
                        defaultLocale
                      ) || "",
                  }}
                ></h4>
                <p>{building.location}</p>
              </div>
              <div className={theme2Styles.bottom}>
                <div className={theme2Styles.features}>
                  {building.bedrooms != 0 && <div className={theme2Styles.li}>
                    <img alt="image" src={bedIcon} />
                    <span>{building.bedrooms}</span>
                  </div>}
                  {building.bathrooms != 0 && <div className={theme2Styles.li}>
                    <img alt="image" src={bathIcon} />
                    <span>{building.bathrooms}</span>
                  </div>}
                  {building.land_area_value && measurement_unit && <div className={theme2Styles.li}>
                    <img alt="image" src={landAreaIcon} />
                    <span>
                      {building.land_area_value}{' ' + (measurement_unit.name || '')}
                    </span>
                  </div>}
                  {building.built_in_area_value && measurement_unit && <div className={theme2Styles.li}>
                    <img alt="image" src={builtAreaIcon} />
                    <span>
                      {building.built_in_area_value}{' ' + (measurement_unit.name || '')}
                    </span>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`${theme2Styles.productRowContainer}`}>
      {(() => {
        if (is_carousel && buildings.length > 4) {
          let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'theme2-product-slider',

            responsive: [
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 899,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 599,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              },
            ]
          };

          return (
            <div className={`${theme2Styles.productSlideContainer}`}>
              <Slider {...settings}>{renderBuildings()}</Slider>
            </div>
          );
        }

        return (
          <div className={`${theme2Styles.productRow}`}>{renderBuildings()}</div>
        );
      })()}
    </div>
  );
};

export default BuildingList;
